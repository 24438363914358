import { ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { ComAPIService } from "../API/com-api.service";
import { tap } from "rxjs";
import { isPlatformBrowser } from "@angular/common";
import { StorageManipulationService } from "../API/storage-manipulation.service";

@Component({
  selector: 'app-sample-auctions',
  templateUrl: './sample-auctions.component.html',
  styleUrls: ['./sample-auctions.component.scss'],
})
export class SampleAuctionsComponent implements OnInit {
  auctions: any;
  ready = false;
  spinner = true
  constructor(
    private service: ComAPIService,
    private ref: ChangeDetectorRef,
    private local: StorageManipulationService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.service
        .getBestAu()
        .pipe(
          tap(item => {
            this.spinner = false
          }),
        ).subscribe((item: any) => {
          //console.log('item', item.headers.get('reload'))
        if(item.headers.get('reload') === 'true') {
          this.local.theItem = ``;
          localStorage.removeItem('user');
          localStorage.removeItem('positionY');
        }
        this.auctions = item.body
        this.ref.detectChanges()
      })
    }
  }

  trackItem(index: number, auction: any) {
    return auction ? auction.auction_id : null;
  }
}
