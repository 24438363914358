import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from "@angular/core";
import { ComAPIService } from "../../API/com-api.service";
import { debounceTime, Subscription } from "rxjs";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ToastrService } from "ngx-toastr";
import { isPlatformBrowser } from "@angular/common";
import { UserInfoComponent } from "../../shared/components/user-info/user-info.component";
import { AuctionInfoComponent } from "../../shared/components/auction-info/auction-info.component";

@Component({
  selector: 'app-all-bids',
  templateUrl: './all-bids.component.html',
  styleUrls: ['./all-bids.component.scss'],
  providers: [MessageService, ConfirmationService,DialogService],
})
export class AllBidsComponent implements OnInit, OnDestroy {
  public get minDate() {
    return new Date(this.formFilter.controls['created_onStart'].value)
  }
  bids: any;
  totalRecords: number = 0;
  rowPerPage = [50, 100, 150, 300];
  visibleRows = 50;
  loadingTable = true;
  formFilter$: Subscription;

  constructor(private service: ComAPIService,
              public dialogService: DialogService,
              private ref: ChangeDetectorRef,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private toastr: ToastrService,
              @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  formFilter = new UntypedFormGroup({
    bids_id: new UntypedFormControl(),
    created_onStart: new UntypedFormControl(''),
    created_onEnd: new UntypedFormControl(''),
    priceStart: new UntypedFormControl(''),
    priceEnd: new UntypedFormControl(''),
    user_id: new UntypedFormControl(''),
    auction_id: new UntypedFormControl(),
    page: new UntypedFormControl(0),
    auctionPerPage: new UntypedFormControl(this.visibleRows),
  });

  dynamicDialogRef: DynamicDialogRef;

  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  show: boolean = true;

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getAllBids(this.formFilter.value);
      this.formFilter$ = this.formFilter.valueChanges
        .pipe(debounceTime(1000))
        .subscribe((value) => {
          // console.log('form', value);
          this.loadingTable = true;
          this.getAllBids(value);
        });
    }
  }

  ngOnDestroy() {
    if (this.formFilter$) return this.formFilter$.unsubscribe();
    if (this.dynamicDialogRef) {
      this.dynamicDialogRef.close();
    }
  }

  getAllBids(filters?: any) {
    this.service
      .getAllBids(filters)
      .subscribe((value) => {
        this.bids = value.bids;
        this.totalRecords = value.totalBids;
        this.loadingTable = false;
        this.ref.detectChanges();
      });
  }

  openDialogUserInfo(accounts: any) {
    this.dynamicDialogRef = this.dialogService.open(UserInfoComponent, {
      data: {
        user_data: accounts,
      },
      header: 'Dane użytkownika',
    });
  }

  openDialogAuctionInfo(auction: any) {
    this.dynamicDialogRef = this.dialogService.open(AuctionInfoComponent, {
      data: {
        user_data: auction,
      },
      header: 'Dane aukcji',
    });
  }
  resetFilters(firstFilter: any, secondFilter?: any) {
    // @ts-ignore
    this.formFilter.controls[`${firstFilter}`].reset();
    if (secondFilter) {
      // @ts-ignore
      this.formFilter.controls[`${secondFilter}`].setValue(null);
    }
  }
  onPageChange(event: any) {
    this.formFilter.get('auctionPerPage').setValue(event.rows);
    this.formFilter.get('page').setValue(event.first / event.rows);

    this.myDiv.nativeElement.scrollIntoView();
  }
}
