import { Injectable } from "@angular/core";
import { devURL } from "../../environments/environment";
import { StorageManipulationService } from "./storage-manipulation.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, share, tap } from "rxjs";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: 'root',
})
export class ComAPIService {
  constructor(
    private local: StorageManipulationService,
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) {}

  public user: any;
  login(nickname: string, password: string): Observable<any> {
    return this.http
      .post<any>(`${devURL.DOMAIN_ADRESS}/${devURL.login}`, {
        username: nickname,
        password: password,
      })
      .pipe(
        tap((item) => {
          //console.log(item);
          this.local.theItem = item.name.name;
        }),
        share()
      );
  }
  register(form: any): Observable<any> {
    return this.http.post<any>(`${devURL.DOMAIN_ADRESS}/${devURL.register}`, {
      form,
    });
  }

  authSMS(form: any): Observable<any> {
    return this.http.post<any>(`${devURL.DOMAIN_ADRESS}/${devURL.authSMS}`, {
      form,
    });
  }

  smsCodeVerify(form: any): Observable<any> {
    return this.http.post<any>(`${devURL.DOMAIN_ADRESS}/${devURL.smsCodeVerify}`, {
      form,
    });
  }

  isAdmin() {
    return this.http.get<any>(`${devURL.DOMAIN_ADRESS}/${devURL.isAdmin}`);
  }

  isLogin() {
    return localStorage.getItem('user');
  }

  logout(message: string) {
    this.local.theItem = ``;
    // this.toastr.success(message);
    this.router.navigate([`../`]);
    localStorage.removeItem('user');
    localStorage.removeItem('positionY');

    return this.http.get<any>(`${devURL.DOMAIN_ADRESS}/${devURL.logout}`).pipe()
  }

  getAllBids(filters?: any) {
    return this.http
      .post<any>(`${devURL.DOMAIN_ADRESS}/${devURL.allBids}`, { filters })
  }

  getAllAccessData() {
    return this.http
      .get<any>(`${devURL.DOMAIN_ADRESS}/${devURL.allAccess}`)
  }

  getRecordScriptsData() {
    return this.http
      .get<any>(`${devURL.DOMAIN_ADRESS}/${devURL.recordScripts}`)
  }
  postAllAccessData(access?: any) {
    return this.http
      .post<any>(`${devURL.DOMAIN_ADRESS}/${devURL.updateAllAccess}`, {access})
  }

  deleteAccessData(access?: any) {
    return this.http
      .post<any>(`${devURL.DOMAIN_ADRESS}/${devURL.deleteAccess}`, {access})
  }

  getActiveAuctions(filters?: any) {
    return this.http
      .post<any>(`${devURL.DOMAIN_ADRESS}/${devURL.activeAuction}`, { filters })
  }

  getAuctionDetails(id: string | null) {
    return this.http.get<any>(`${devURL.DOMAIN_ADRESS}/${devURL.auctionDetails}/${id}`).pipe(share());
  }

  getUserDetails() {
    return this.http.get<any>(`${devURL.DOMAIN_ADRESS}/${devURL.auctionDetails}`).pipe(share());
  }

  getAllMarks(filters?: any) {
    return this.http.post<any>(`${devURL.DOMAIN_ADRESS}/${devURL.marks}`, { filters }).pipe(share());
  }

  getAllModels(filters?: any) {
    return this.http.post<any>(`${devURL.DOMAIN_ADRESS}/${devURL.models}`, { filters }).pipe(share());
  }

  getBestAuction() {
    return this.http.get<any>(`${devURL.DOMAIN_ADRESS}/api/bestAu`).pipe(share());
  }

  getBestAu() {
    return this.http.get<any>(`${devURL.DOMAIN_ADRESS}/api/bestAu`, { observe: 'response' }).pipe(share());
  }
  postBidAuction(auctionID: number, price: number) {
    return this.http.post<any>(`${devURL.DOMAIN_ADRESS}/${devURL.auctionBid}`, {
      auction_id: auctionID,
      offer: price,
    });
  }

  deleteUserAccount(userID: number) {
    let params = new HttpParams({ fromObject: {'user_ID': userID} });
    return this.http.get<any>(`${devURL.DOMAIN_ADRESS}/${devURL.userDelete}`, {
      params: params
    });
  }

  forgotPassword(email: string) {
    let params = new HttpParams({ fromObject: {'email': email} });
    return this.http.get<any>(`${devURL.DOMAIN_ADRESS}/${devURL.resetPassword}`, {
      params: params
    });
  }
  setNewPassword(password: string, hash: string) {
    let params = new HttpParams({ fromObject: {'password': password, hash: hash} });
    return this.http.get<any>(`${devURL.DOMAIN_ADRESS}/${devURL.setNewPassword}`, {
      params: params
    });
  }
  changeUserStatus(user: any) {
    let params = new HttpParams({ fromObject: {'user_ID': user.user_id, 'userStatus': !user.activ }});
    return this.http.get<any>(`${devURL.DOMAIN_ADRESS}/${devURL.userStatus}`, {
      params: params
    });
  }
  changeSpecialUserStatus(user: any) {
    let params = new HttpParams({ fromObject: {'user_ID': user.user_id, 'specialUserStatus': user.specialUser }});
    return this.http.get<any>(`${devURL.DOMAIN_ADRESS}/${devURL.specialUserStatus}`, {
      params: params
    });
  }
  changeAUtoBetsStatus(autoBet: any) {
    let params = new HttpParams({ fromObject: {'name': autoBet.name, 'status': autoBet.status }});
    return this.http.get<any>(`${devURL.DOMAIN_ADRESS}/${devURL.autoBetsStatus}`, {
      params: params
    });
  }
  deleteAuction(auctionID: number, auction_ids: string) {
    let params = new HttpParams({ fromObject: {'auction_ID': auctionID, 'auction_ids' : auction_ids} });
    return this.http.get<any>(`${devURL.DOMAIN_ADRESS}/${devURL.auctionDelete}`, {
      params: params
    });
  }
  userActive(activeToken: any) {
    let hash = {
      hash: activeToken,
    };
    const params = new HttpParams({ fromObject: hash });
    return this.http.get<any>(`${devURL.DOMAIN_ADRESS}/${devURL.activeUser}`, { params });
  }

  getAllUser(filters?: any) {
    return this.http.post<any>(`${devURL.DOMAIN_ADRESS}/${devURL.allUsers}`, { filters });
  }

  getAllAuctions(filters?: any) {
    return this.http.post<any>(`${devURL.DOMAIN_ADRESS}/${devURL.allAuctions}`, { filters });
  }

  getMyAuctions(filters?: any) {
    return this.http.post<any>(`${devURL.DOMAIN_ADRESS}/${devURL.myAuctions}`, { filters });
  }

  getMyUserDetails() {
    return this.http.get<any>(`${devURL.DOMAIN_ADRESS}/${devURL.settings}`).pipe(share());
  }

  getScriptsInfo() {
    return this.http.get<any>(`${devURL.DOMAIN_ADRESS}/${devURL.scripts}`);
  }
  getAutoBetsInfo() {
    return this.http.get<any>(`${devURL.DOMAIN_ADRESS}/${devURL.autoBets}`);
  }
  getScrapper(scriptName: string) {
    return this.http.get<any>(`${devURL.DOMAIN_ADRESS}/${devURL.scrapper}/${scriptName}`);
  }

  uploadFile(file: any[], date?: Date) {
      let formData = new FormData();
      formData.append('Date', date.toString())
      for (let i=0; i < file.length; i++) {
        formData.append('files', file[i]);
      }

      return this.http.post<any>(`${devURL.DOMAIN_ADRESS}/${devURL.upload}`,
        formData
      );
    }
}
